import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { vendor1,vendor2,vendor3 } from "./assets";
// Customizable Area Start
 
// Customizable Area End



export interface Props {
  navigation: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  Event_detail: boolean;
  current_status: boolean;
  currentEventScreen:boolean;
  EventData:    {Eventdata:string,EventVenue:string,Eventtype:string,EventSite:string,attendee:string,roombooked:string};
  vendorData:{img:string,title:string,content:string}[]

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyEventController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDynamicFormTemplateId: string = "";
  createReverseAuctionCallId: string = "";
  
  // Customizable Area End 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      Event_detail:true,
      current_status:false,
      currentEventScreen:false,
      vendorData:[
        {img:vendor1,title:"Sheraton Grand",content:"Venue"},
        {img:vendor2,title:"Marriage colours ",content:"Planner"},
        {img:vendor3,title:"Brindha Makeup ",content:"Bridal Makeup"}
    ],
      EventData:{ Eventdata:"Thu,Jan 02,2025, Fri, Jan 03,2025",EventVenue:"Sheraton grand",Eventtype:"Wedding-Beach side",EventSite:"Reception- Ball room",attendee:"1000",roombooked:"40"}
    }
   
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   

  // Customizable Area Start

  handleEvent = ()=>{
    this.setState({Event_detail:!this.state.Event_detail,current_status:!this.state.current_status})
  }

  handleCurrentStatus = ()=>{
    this.setState({current_status:!this.state.current_status,Event_detail:!this.state.Event_detail})
  }

  handleNavigation =()=>{
      this.setState({currentEventScreen:true})
  }
  // Customizable Area End
}
