import React from "react";

// Customizable Area Start

import Loader from "../../../../components/src/AppLoader.web"
import { styled, Box, Typography } from "@mui/material";
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
// Customizable Area End

import HappiffieWorldController, { Props } from "./HappiffieWorldController.web";
import { Banner1, Banner2, chat, innerrbanner1, innerrbanner2, innerrbanner3, myEvent, myShop, myVendor, planningTool, reverseAuction, subscription, wishList } from "../assets";



export default class HappffieWorld extends HappiffieWorldController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    const { isLoading } = this.state;
    return (
      // Customizable Area Start
      <>
     
     
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={isLoading} />
          <StyleWrapper>
             <Box>
                <Box >
                    <Box className="Header">                           
                           <Typography sx={{textAlign:'center',fontWeight:500,fontSize:'40px',color:'#000000',lineHeight:'52px',height:'52px'}}>
                                  Happiffie world, Maria James
                           </Typography>
                    </Box>

                    <Box className="listItems">
                              <Box className="card" data-test-id="navigation" onClick={()=>this.handleMyEvent()}>
                                <img src={myEvent} className="img"></img>
                                <Typography className="imgtypography">My Event</Typography>
                              </Box>
                              <Box className="card2">
                                <img src={myVendor} className="img"></img>
                                <Typography className="imgtypography">My Vendor</Typography>
                              </Box>
                              <Box className="card3">
                                <img src={myShop} className="img"></img>
                                <Typography className="imgtypography2">My Shop</Typography>
                              </Box>
                              <Box className="card">
                                <img className="img" src={reverseAuction} />
                                <Typography className="imgtypography">Reverse Auction</Typography>
                              </Box>
                              <Box className="card">
                                <img src={chat} className="img"></img>
                                <Typography className="imgtypography3">Chat</Typography>
                              </Box>
                              <Box className="card3">
                                <img src={wishList} className="img"/>
                                <Typography className="imgtypography">Wishlist</Typography>
                              </Box>
                              <Box className="card2">
                                <img  className="img" src={planningTool}></img>
                                <Typography className="imgtypography3">Planning tool</Typography>
                              </Box>
                              <Box className="card2">
                                <img src={subscription} className="img"/>
                                <Typography className="imgtypography">Subscription</Typography>
                              </Box>
                    </Box>

                    <Box className="banner1" >
                        <img src={Banner1} width="100%"/>
                    </Box>

                    <Box className="innerbanner">
                           <Typography className="bannerTitle"> A Day to Remember: Celebrating Our Wedding Story</Typography>
                           <Typography className="bannerContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                           <Box sx={{display:'flex',gap:'13px',marginTop:'45px'}}>
                                <img src={innerrbanner1} className="imgcontainer"/>
                                <img src={innerrbanner2} className="imgcontainer"/>
                                <img src={innerrbanner3} className="imgcontainer"/> 
                           </Box>
                    </Box>


                    <Box className="innerbanner">
                           <Typography className="bannerTitle"> A Day to Remember: Celebrating Our Wedding Story</Typography>
                           <Typography className="bannerContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                           <Box sx={{display:'flex',marginTop:'45px',gap:'12px',marginBottom:'20px'}}>
                                <img src={Banner2} style={{width:'100%'}} />                               
                           </Box>
                    </Box>
                </Box>
             </Box>
          </StyleWrapper>
      </HeaderFooterLayout>
     
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyleWrapper = styled(Box)({
 "& .card": {
    border:'2px solid #CBD5E1',
    borderRadius:'8px'
  },
  "& .card2": {
    borderRadius:'8px',
    border:'2px solid #CBD5E1',
    
  }, 
  
"& .img": {
    padding:'22px 40px',
    paddingBottom:'15px'
  },
"& .imgtypography": {
    marginBottom:'22px',
    textAlign:'center',
    fontSize:'16px',
    fontWeight:600,
    
  },
  "& .imgtypography2": {
    fontWeight:600,
    textAlign:'center',
    fontSize:'16px',
    marginBottom:'22px'
  }, 
  "& .listItems": {
   display:'flex',
   gap:'12px',
   padding:' 0 5%',   
  },
  "& .imgtypography3": {
    textAlign:'center',
    fontSize:'16px',
    fontWeight:600,
    marginBottom:'22px'
  },
  "& .banner1": {
    marginTop: '50px',  
  },
  "& .Header": {
    backgroundColor:'white',
    paddingTop:'30px',
    paddingBottom:'25px'

  },
  "& .innerbanner": {
   padding:'0 2%',
   marginTop:'40px'
  },
  "& .bannerTitle": {
   textAlign:'center',
   fontWeight:500,
   fontSize:'24px',
   lineHeight:'37px',
   color:'#1C1917'    
  },
  "& .bannerContent": { 
    marginTop:'20px', 
    textAlign:'center',
    fontSize:'16px',
    fontWeight:400,
    lineHeight:'26px'
  },
"& .card3": {
   borderRadius:'8px 8px',
    border:'2px solid #CBD5E1',
   
  },
  
  "& .imgcontainer": {
    width:'425px',
    borderRadius:'8px'
  },
 
});
// Customizable Area End
