export const logo1 = require("../assets/logo1.png");
export const logo2 = require("../assets/logo2.png");
export const logo3 = require("../assets/logo3.png");
export const logo4 = require("../assets/logo4.png");
export const avatarImg = require("../assets/avatar.png");
export const myEvent = require("../assets/myEvent.png");
export const myVendor = require("../assets/myvendor.png");
export const myShop = require("../assets/myshop.png");
export const chat = require("../assets/chat.png");
export const reverseAuction = require("../assets/reverseauction.png");
export const subscription = require("../assets/subscription.png");
export const wishList = require("../assets/wishlist.png");
export const planningTool = require("../assets/planningTool.png");
export const Banner1 = require("../assets/Banner1.png");
export const Banner2 = require("../assets/banner2.png");
export const innerrbanner1= require("../assets/innerBanner1.png")
export const innerrbanner2 = require("../assets/innerbanner2.png");
export const innerrbanner3 = require("../assets/innerBanner3.png");
export const event1 = require("../assets/event1.png");
export const banner3 = require("../assets/banner3.png");
export const vendor1 = require("../assets/vendor1.png");
export const vendor2 = require("../assets/vendor2.png")
export const vendor3 = require("../assets/vendor3.png")




