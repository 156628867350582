import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { apiCall, redirectToPage } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area Start
function replaceKeys(obj: unknown): unknown {
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceKeys(item));
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj as Record<string, unknown>).map(([key, value]) => [
        key.replace(/\s+/g, "_"), // Replace spaces with underscores
        replaceKeys(value),
      ])
    );
  }

  return obj;
}
export interface FormField {
  field_name: string;
  field_type: string;
  options: string[];
  required: boolean;
}

interface AuctionForm {
  id: number;
  title: string;
  account_id: number | null;
  created_at: string;
  updated_at: string;
  form_fields: FormField[];
}

interface DropdownData {
  id: number,
  label: string,
  value: string,
}

interface ReverseAuction {
  id: string,
  type: string,
  attributes: ReverseAuctionAttributes,
}

interface ReverseAuctionAttributes {
  id: number;
  city: string;
  venue: string;
  pincode: string;
  event: string;
  sub_event: string;
  event_start_date: string;
  event_end_date: string;
  session_dates: string[];
  category: string[];
  food_beverages: string[];
  decor: string[];
  menu: string;
  comments?: string;
  finalization_date?: string;
  budget?: string;
  service_details: string;
  status: string;
  vendor_criteria: string;
}

interface EnquiryData{
  status?:string;
}

interface EnquiryData2 {
  Full_Name?: string; 
  Event?: string;
  Venue?: string;
  City?: string;
  Sub_Event?: string;
  Session?: string;  
  Category?: string;
  Number_of_Guest?: string;
  Comments?: string;
  Budget?: string;
  "Event_Date_(Date_range_can_be_selected)"?: string;
}

// Customizable Area End

// export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  formFields: FormField[];
  formData: any;
  anchorEl: HTMLElement | null;
  reverseAuctionElementId:string | undefined;
  reverseAuctionData: [];
  vendorAuctionData:[]
  formTemplateId: number;
  loginRole:string;
  enquiryData:EnquiryData;
  enquiryData2:EnquiryData2;
  BookedOn:string | Date
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReverseAuctionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDynamicFormTemplateId: string = "";
  createReverseAuctionCallId: string = "";
  getReverseAuctionDataId: string = "";
  getReverseAuctionVendorDataId:string = "";
  getEnquiryDataID:string = ""
  createAcceptEnquiryID:string=""
  createRejectEnquiryID:string = ""
  deleteReverseAuctionUserId:string = "";
  // Customizable Area End 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      formFields: [],
      formData: {},
      anchorEl: null,
      reverseAuctionData: [],
      vendorAuctionData:[],
      formTemplateId: 0,
      loginRole:"",
      enquiryData:{} ,
      enquiryData2:{},
      BookedOn:'',
      reverseAuctionElementId:""
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
    
      switch (webApiRequestCallId) {
       
        case this.getDynamicFormTemplateId:
          this.handleDynamicFormTemplateResponse(webResponseJson);
          break;

        case this.createReverseAuctionCallId:
          this.handleCreateReverseAuctionResponse(webResponseJson);
          break;
        case this.getReverseAuctionDataId:
          this.handleReverseAuctionDataResponse(webResponseJson);      
          break;
        case this.getEnquiryDataID:        
            this.handleEnquiryDataResponse(webResponseJson);
          break;
        case this.getReverseAuctionVendorDataId:
            this.handleReverseAuctionDataResponse2(webResponseJson);
          break;
        case this.deleteReverseAuctionUserId:
             this.handleReverseAuctionDeleteResponse(webResponseJson);
        default:
          break;
      }
      if(this.getReverseAuctionVendorDataId===webApiRequestCallId){
        this.handleReverseAuctionDataResponse2(webResponseJson) ;
      }
      if(this.createAcceptEnquiryID===webApiRequestCallId){
        this.handleAcceptResponse(webResponseJson) ;
      }

      if( this.createRejectEnquiryID===webApiRequestCallId){
        this.handleRejectResponse(webResponseJson)  
      }
    }
    // Customizable Area End
  }
 

  // Customizable Area Start

  async componentDidMount() {
    this.getDynamicFormTemplate();
    this.getReverseAuctionData();
    this.showReverseAuctionForm();
    this.getVendorReverseAuctionData();
    this.getEnquiryData();
  }

  showReverseAuctionForm = () => {   
    const storedValue = window.localStorage.getItem("role");     
    this.setState({ loginRole: storedValue || "null" }, () => {      
    }) 
   
    
  }
  openReverseAuctionform = async () => {
    const access = await getStorageData("authToken");
    if(access){
      this.props.navigation.navigate("AddReverseAuction");
    } else {
      toast.error("Please Login to add reverse auction");
    }
  }
  
  openReverseAuctionList = () => {
    this.props.navigation.navigate("ReverseAuctionList");
  }

  openDashboard = () => {
    redirectToPage(this.props.navigation, "Dashboard");
  }

  getDynamicFormTemplate = async () => {
    this.setState({ isLoading: true });

    this.getDynamicFormTemplateId = await apiCall({
      endPoint: "bx_block_custom_form/form_templates",
      method: "GET",
    });
  };

  getEnquiryData = async ()=>{
    const data =  JSON.parse(localStorage.getItem("enquiry_id") || "{}")
    const id= data   
    this.getEnquiryDataID = await apiCall({      
      endPoint: `/bx_block_posts/reverse_auctions/${id}/enquiry_auction`,     
      method: "GET",
    });

  }
  handleDynamicFormTemplateResponse = (response: AuctionForm[]) => {
    const template = response.find(({ title }) =>
      title.toLowerCase().replace(/\s/g, "").includes("reverseauction")
    );
    if (template) {
      this.setState({
        formFields: template?.form_fields || [],
        isLoading: false,
        formTemplateId: template?.id,
      });
    } else {
      this.setState({
        formFields: [],
        isLoading: false,
        formTemplateId: 0,
      });
    }
  };

  handleChange = (fieldName: string, value: string) => {
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [fieldName]: value },
    }));
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { formData, formTemplateId } = this.state; 
    const requestBody = {
      form_template_id: formTemplateId,
      form_data:formData        
    }

    this.createReverseAuctionCallId = await apiCall({
      token:await getStorageData("authToken"),     
      endPoint: "bx_block_posts/reverse_auctions/submit_form",
      method: "POST",
      body: JSON.stringify(requestBody)
    });    
  };
  
  handleCreateReverseAuctionResponse = (response: any) => {
    if (response.error) {
      toast.error(response.error);
    }
    if (response.errors) {
      toast.error(response.errors);
    }
    this.setState({
      isLoading: false
    });
    if(!response.error){
      toast.success("Form Submitted Successfully")
    }
    this.openReverseAuctionList()    
  }; 

  handleInputChange = (event: any) => {
    if (!event.target) {     
      return;
    }
  
    const { name, value } = event.target;
  
    if (name) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  
  

  renderStringValueDropdown = (value: string, dropdownData: DropdownData[]) => {
    return dropdownData.find((item) => item.value === value)?.label;
  }

  handleClick = (event: any, id?:string) => {
    this.setState({ anchorEl: event.currentTarget, reverseAuctionElementId:id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseUser = async ( num:number ) => {
    this.deleteReverseAuctionUserId = await apiCall({
      endPoint: `bx_block_posts/reverse_auctions/${num}/remove_submit_auction`,
      method: "DELETE",
    });
  };

  getReverseAuctionData = async () => {
    this.setState({ isLoading: true });

    this.getReverseAuctionDataId = await apiCall({
      endPoint: "bx_block_posts/reverse_auctions/show_user_auctions",
      method: "GET",
    });

    
  };

  handleRowClick = (e:any)=>{   
   this.props.navigation.navigate("ReverseAuctionEnquiry")
   localStorage.setItem('enquiry_id',JSON.stringify(e))
   
  }

getVendorReverseAuctionData= async() =>{  
  this.setState({ isLoading: true })
  this.getReverseAuctionVendorDataId = await apiCall({
    endPoint: "bx_block_posts/reverse_auctions",
    method: "GET",
  });
}

  handleReverseAuctionDataResponse = (response: any) => {     
    
    if (response) {
      this.setState({
        reverseAuctionData:replaceKeys(response) as any
      });
    }
    this.setState({ isLoading: false });
   
  };

  handleReverseAuctionDataResponse2 = (response: any) => {   
   
    if (response.data.length>0){
      this.setState({
        vendorAuctionData:replaceKeys(response.data) as any        
      });
    }    
   
  };

   formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric"
    });
};
  handleEnquiryDataResponse = (response: any) => {   

    if (response?.auction) {
      
      this.setState({
        enquiryData:replaceKeys(response.auction) as any , 
        enquiryData2:replaceKeys(response.auction.submitted_data) as any   ,
        BookedOn:this.formatDate(response?.auction.created_at)            
      });
    }    
   
  };

  handleAccept = async()=>{
      const data =  JSON.parse(localStorage.getItem("enquiry_id") || "{}")
      const id= this.state.reverseAuctionElementId? this.state.reverseAuctionElementId:data
      this.createAcceptEnquiryID = await apiCall({
        token:await getStorageData("authToken"),     
        endPoint: `bx_block_posts/reverse_auctions/${id}/accept`,
        method: "POST",
        
      }); 
     
  }

  handleReject = async()=>{
   
     const data =  JSON.parse(localStorage.getItem("enquiry_id") || "{}")
     const id= this.state.reverseAuctionElementId? this.state.reverseAuctionElementId:data
      this.createRejectEnquiryID = await apiCall({
        token:await getStorageData("authToken"),     
        endPoint: `bx_block_posts/reverse_auctions/${id}/reject`,
        method: "POST",
        
      }); 
  }

  handleAcceptResponse  = (response: any) => { 
      this.handleClose();  
      toast.success(response.message)
      this.getVendorReverseAuctionData();
      this.resetReverseAuctionElementId();   
      this.props.navigation.navigate("ReverseAuctionList"); 
  };

  handleRejectResponse  = (response: any) => {  
    this.handleClose();
    toast.success(response.message)
    this.getVendorReverseAuctionData();
    this.resetReverseAuctionElementId();
    this.props.navigation.navigate("ReverseAuctionList");
   };

   resetReverseAuctionElementId = ()=>{
    this.setState({ reverseAuctionElementId:undefined });
   }

  handleReverseAuctionDeleteResponse = (response: any) => {   
    if(response.success)
      this.setState({ anchorEl: null });
      this.getReverseAuctionData();
      toast.success("Auction successfully cancelled.");
  };

  // Customizable Area End
}
