import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End



export interface Props {
  navigation: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HappiffieWorldController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDynamicFormTemplateId: string = "";
  createReverseAuctionCallId: string = "";  
  // Customizable Area End 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
     
     
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  } 
  // Customizable Area Start
  handleMyEvent = ()=>{
    this.props.navigation.navigate("MyEvent");
  }

  // Customizable Area End
}
