import React from "react";

// Customizable Area Start

import Loader from "../../../components/src/AppLoader.web"
import { styled, Box, Button,  Typography } from "@mui/material";
import HeaderFooterLayout from "../../../../packages/components/src/HeaderFooterLayout";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {  Banner2, banner3, chat, event1,  myEvent, myShop, myVendor, planningTool, reverseAuction, subscription, wishList } from "./assets";
// Customizable Area End

import MyEventController, { Props } from "../src/MyEventController.web";



export default class MyEvent extends MyEventController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const { isLoading } = this.state;
        const { Event_detail, current_status } = this.state
        return (
            // Customizable Area Start
            <>


                <HeaderFooterLayout navigation={this.props.navigation}>
                    <Loader isLoading={isLoading} />
                    {!this.state.currentEventScreen && (
                        <StyleWrapper>
                            <Box className="Header">
                                <Typography sx={{ textAlign: 'center', fontWeight: 500, fontSize: '40px', color: '#000000', lineHeight: '52px', height: '52px' }}>
                                    Happiffie world, Maria James
                                </Typography>
                            </Box>

                            <Box className="listItems">
                                <Box className="card" sx={{
                                    backgroundColor: "#7F1187",
                                    transition: "background-color 0.3s ease-in-out",
                                    "& .img": {
                                        filter: "brightness(0) invert(1)",
                                    },
                                    "& .imgtypography": {
                                        color: "white",
                                    },
                                }}>
                                    <img src={myEvent} className="img"></img>
                                    <Typography className="imgtypography">My Event</Typography>
                                </Box>
                                <Box className="card">
                                    <img src={myVendor} className="img"/>
                                    <Typography className="imgtypography">My Vendor</Typography>
                                </Box>
                                <Box className="card2">
                                    <img src={myShop} className="img"></img>
                                    <Typography className="imgtypography">My Shop</Typography>
                                </Box>
                                <Box className="card3">
                                    <img src={reverseAuction} className="img"></img>
                                    <Typography className="imgtypography">Reverse Auction</Typography>
                                </Box>
                                <Box className="card3">
                                    <img src={chat} className="img"/>
                                    <Typography className="imgtypography3">Chat</Typography>
                                </Box>
                                <Box className="card">
                                    <img  className="img" src={wishList} ></img>
                                    <Typography className="imgtypography">Wishlist</Typography>
                                </Box>
                                <Box className="card3">
                                    <img src={planningTool} className="img"></img>
                                    <Typography className="imgtypography2">Planning tool</Typography>
                                </Box>
                                <Box className="card2">
                                    <img  className="img" src={subscription}/>
                                    <Typography className="imgtypography">Subscription</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ marginTop: '30px' }}>
                                <img src={Banner2} style={{ width: '100%' }} />
                            </Box>

                            <Box className="container">
                                <Box className="current_event">
                                    <Typography sx={{ fontWeight: 500, fontSize: '28px', lineHeight: '50px' }}>Current Event</Typography>
                                    <Button sx={{ fontWeight: 600, fontSize: '16px', color: '#7F1187', lineHeight: '24px', textTransform: "none", }} endIcon={<AddCircleOutlineIcon sx={{ color: "#8A0F8A" }} />}>Add New Event</Button>
                                </Box>

                                <Box className="container_card">

                                    <Box sx={{ width: '50%', border: '1px solid #CBD5E1', borderRadius: '12px', padding: '0px 30px 40px 30px', backgroundColor: 'white' }}>
                                        <Box className="btn">
                                            <Box sx={{ backgroundColor: "#ebf3ff", borderRadius: '30px' }}>
                                                <Button sx={{
                                                    backgroundColor: Event_detail ? '#7F1287' : '#ebf3ff', color: Event_detail ? 'white' : 'black', textTransform: 'none', padding: '10px 35px', borderRadius: '25px', marginTop: '3px', fontWeight: 600, fontSize: '18px', lineHeight: '24px', '&:hover': {
                                                        backgroundColor: Event_detail ? '#7F1287' : '#ebf3ff'
                                                    }
                                                }} onClick={() => this.handleEvent()} data-test-id="event">Event Details</Button>
                                                <Button sx={{
                                                    backgroundColor: this.state.current_status ? '#7F1287' : '#ebf3ff', color: current_status ? 'white' : 'black', textTransform: 'none', padding: '10px 35px', borderRadius: '25px', marginTop: '3px', fontWeight: 600, fontSize: '18px', lineHeight: '24px', '&:hover': {
                                                        backgroundColor: current_status ? '#7F1287' : '#ebf3ff'
                                                    }
                                                }} onClick={() => this.handleCurrentStatus()} data-test-id="currentStatus">Current Status</Button>
                                            </Box>
                                            <Box sx={{ padding: '5px 5px 0px 0px' }}>
                                                <Button sx={{
                                                    backgroundColor: 'white', color: '#7F1287', border: '1px solid #7F1287', padding: '6px 30px', borderRadius: '25px', textTransform: 'none', '&:hover': {
                                                        backgroundColor: 'white', color: '#7F1287'
                                                    }
                                                }}>Edit</Button>
                                            </Box>
                                        </Box>
                                        <Box className="card_content">
                                            <Box className="content_area">
                                                <Typography sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '24px' }}>Anjali & Vikram Wedding</Typography>

                                                <Box sx={{ marginTop: '15px' }}>
                                                    <Typography className="content_area_typography">Event Date: Thu, Jan 02,2025, Fri, Jan 03,2025
                                                    </Typography>
                                                    <Typography className="content_area_typography">Event Venue: Sheraton grand
                                                    </Typography>
                                                    <Typography className="content_area_typography">Event Type: Wedding - Beach side
                                                    </Typography>
                                                    <Typography className="content_area_typography">
                                                        Event Site: Reception - Ball room
                                                    </Typography >
                                                    <Typography className="content_area_typography">Attendee: 1000
                                                    </Typography>
                                                    <Typography className="content_area_typography">Room Booked: 40</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="event_image" sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => this.handleNavigation()} data-test-id="navigation">
                                                <img src={event1} style={{ flexGrow: '1' }} />
                                            </Box>
                                        </Box>
                                    </Box>



                                </Box>

                            </Box>

                            <Box className="container">
                                <Box className="current_event">
                                    <Typography sx={{  fontSize: '28px', lineHeight: '50px',fontWeight: 500 }}>Past Events</Typography>
                                    <Button sx={{ color: '#7F1187',fontWeight: 600, fontSize: '16px', lineHeight: '24px', textTransform: "none", }} endIcon={<AddCircleOutlineIcon sx={{ color: "#8A0F8A" }} />}>Add New Event</Button>
                                </Box>

                                <Box className="container_card">

                                    <Box sx={{ width: '50%', border: '1px solid #CBD5E1', borderRadius: '12px', padding: '0px 30px 40px 30px', backgroundColor: 'white' }}>
                                        <Box className="btn">
                                            <Box sx={{ backgroundColor: "#ebf3ff", borderRadius: '30px' }}>
                                                <Button sx={{
                                                    backgroundColor: Event_detail ? '#7F1287' : '#ebf3ff', color: Event_detail ? 'white' : 'black', padding: '10px 35px', textTransform: 'none', borderRadius: '25px', marginTop: '3px', fontWeight: 600, fontSize: '18px', lineHeight: '24px', '&:hover': {
                                                        backgroundColor: Event_detail ? '#7F1287' : '#ebf3ff'
                                                    }
                                                }} onClick={() => this.handleEvent()} data-test-id="events">Event Details</Button>
                                                <Button sx={{
                                                    backgroundColor: this.state.current_status ? '#7F1287' : '#ebf3ff', color: current_status ? 'white' : 'black', textTransform: 'none', padding: '10px 35px', borderRadius: '25px', marginTop: '3px', fontWeight: 600, fontSize: '18px', lineHeight: '24px', '&:hover': {
                                                        backgroundColor: current_status ? '#7F1287' : '#ebf3ff'
                                                    }
                                                }} onClick={() => this.handleCurrentStatus()} data-test-id="status">Current Status</Button>
                                            </Box>
                                            <Box sx={{ padding: '5px 5px 0px 0px' }}>
                                                <Button sx={{
                                                    backgroundColor: 'white', padding: '6px 30px', color: '#7F1287',  borderRadius: '25px', border: '1px solid #7F1287',textTransform: 'none', '&:hover': {
                                                        backgroundColor: 'white', color: '#7F1287'
                                                    }
                                                }}>Edit</Button>
                                            </Box>
                                        </Box>
                                        <Box className="card_content">
                                            <Box className="content_area">
                                                <Typography sx={{ lineHeight: '24px',fontWeight: 600, fontSize: '24px',  }}>Anjali & Vikram Wedding</Typography>

                                                <Box sx={{ marginTop: '15px' }}>
                                                    <Typography className="content_area_typography">Event Date: Thu, Jan 02,2025, Fri, Jan 03,2025
                                                    </Typography>
                                                    <Typography className="content_area_typography">Event Venue: Sheraton grand
                                                    </Typography>
                                                    <Typography className="content_area_typography">Event Type: Wedding - Beach side
                                                    </Typography>
                                                    <Typography className="content_area_typography">
                                                        Event Site: Reception - Ball room
                                                    </Typography >
                                                    <Typography className="content_area_typography">Attendee: 1000
                                                    </Typography>
                                                    <Typography className="content_area_typography">Room Booked: 40</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="event_image" sx={{ flexDirection: 'column',display: 'flex',  }}>
                                                <img src={event1} style={{ flexGrow: '1' }} />
                                            </Box>
                                        </Box>
                                    </Box>



                                </Box>

                            </Box>

                        </StyleWrapper>
                    )}


                    {
                        this.state.currentEventScreen && (
                            <EventWrapper>
                                <Box className="banner">
                                    <img src={banner3} />

                                    <Box className="bannerContent">
                                        <Typography className="bannerTitle" sx={{}}>
                                            Anjali & Vikram Wedding
                                        </Typography>
                                        <Button className="bannerContent_btn">


                                            Vendor Booked
                                        </Button>
                                    </Box>
                                </Box>
                                <Box className="contentArea">
                                    <Box className="AboutEvents">
                                        <Typography sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '50px' }}>About Event</Typography>
                                        <Box>
                                            <Typography className="contentArea_typography">Event Date: {this.state.EventData.Eventdata}</Typography>
                                            <Typography>Event Venue: {this.state.EventData.EventVenue}</Typography>
                                            <Typography>Event Type: {this.state.EventData.Eventtype}</Typography>
                                            <Typography>Event Site: {this.state.EventData.Eventtype}</Typography>
                                            <Typography>Attendee: {this.state.EventData.attendee}</Typography>
                                            <Typography>Room Booked: {this.state.EventData.roombooked}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="AboutVendors">
                                        <Typography sx={{fontWeight:600,fontSize:'24px',}}>Vendors</Typography>
                                        <Box sx={{display:'flex', flexDirection:'row',gap:'50px'}}>
                                            {this.state.vendorData.map((value, index) => {
                                                const img = value.img
                                                return (
                                                    <Box key={index} sx={{marginTop:'35px',padding:'0px 10px'}}>                                                       
                                                        <img src={img}/>
                                                        <Typography sx={{fontSize:'20px',fontWeight:600,lineHeight:'28px',textAlign:"center",marginTop:'10px'}}>{value.title}</Typography>
                                                        <Typography  sx={{fontSize:'14px',fontWeight:400,lineHeight:'24px',textAlign:"center",marginTop:"5px"}}>{value.content}</Typography>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                </Box>
                            </EventWrapper>

                        )
                    }
                </HeaderFooterLayout>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const StyleWrapper = styled(Box)({
    "& .card": {
        border: '2px solid #CBD5E1',
        borderRadius: '8px'
    },
    "& .card2": {
        borderRadius: '8px',
        border: '2px solid #CBD5E1',
        
    },
    "& .card3": {

        border: '2px solid #CBD5E1',
        borderRadius: '8px 8px'
    },
    "& .img": {
        padding: '22px 40px',
        paddingBottom: '15px'
    },
    "& .imgtypography": {
        textAlign: 'center',
        fontSize: '16px', fontWeight: 600,
        marginBottom: '22px'
    },
    "& .imgtypography2": {
        textAlign: 'center',
        fontSize: '16px', fontWeight: 600,
        marginBottom: '22px'
    },
    "& .listItems": {
        display: 'flex',
        gap: '12px',
        padding: ' 0 5%',
    },
    "& .banner1": {
        marginTop: '50px',
    },
    "& .Header": {
        backgroundColor: 'white',
        paddingTop: '30px',
        paddingBottom: '25px'

    },
    "& .innerbanner": {
        padding: '0 2%',
        marginTop: '40px'
    },
    "& .bannerTitle": {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '37px',
        color: '#1C1917'
    },
    "& .imgtypography3": {
        textAlign: 'center',
        fontSize: '16px', fontWeight: 600,
        marginBottom: '22px'
    },
    "& .bannerContent": {
        marginTop: '20px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '26px'
    },

    "& .container": {
        marginTop: '30px',
        padding: '0 2%'
    },
    "& .current_event": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .container_card": {
        marginTop: '25px',
        display: 'flex',
        gap: '25px'
    },
    "& .btn": {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '20px',
        marginTop: '35px'
    },

    "& .card_content": {
        marginTop: '30px',
        display: 'flex',
        gap: '25px'
    },
    "& .content_area": {
        width: "60%"
    },
    "& .event_image": {
        widht: "35%",

    },
    "& . content_area_typography": {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '26px',
        color: '#475569',
        marginTop:'5px'
    },
  

});


const EventWrapper = styled(Box)({
    "& .banner": {
        position: "relative",
        display: "inline-block",
        width: "100%",
    },
    "& .bannerContent": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        padding: "20px",
        borderRadius: "10px",
    },
    "& .bannerContent_btn": {
        marginTop: "25px",
        backgroundColor: "#FFFFFF",
        color: "black",
        padding: '10px 50px',
        borderRadius: '50px',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '26px',
        textTransform: 'none',
        "&:hover": { backgroundColor: "#FFFFFF" }
    },
    "& .bannerTitle": {
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    "& .contentArea": {
        padding: '0px 30px',
        marginTop: '30px',
        marginBottom: '40px'
    },
    "& . contentArea_typography": {
        marginTop: '8px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '26px',
        color: "#475569",
    },
    "& .AboutVendors ": {
        marginTop: '30px',
    },
   
})

// Customizable Area End
